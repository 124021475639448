import React from 'react';

import { MenuItem } from '@mui/material';

import { StyledSelect } from 'components/Input/styles';

import { ProtectorType } from 'utils/api/types';

import { getEntityTranslation } from 'stores/EntityDetails/utils';

import { Container } from './styles';
import { EditComponentForProtectorTypeProps } from './types';

const EditComponentForProtectorType: React.FC<EditComponentForProtectorTypeProps> = ({
  questionType,
  lookupData,
  activeEntityModules,
  onSettingLookup,
}): React.ReactElement => {
  const [lookupValue, setLookupValue] = React.useState<string>(lookupData || '');

  switch (questionType) {
    case ProtectorType.ShortText:
      return <Container>Short-text area</Container>;
    case ProtectorType.LongText:
      return <Container>Long-text area</Container>;
    case ProtectorType.Number:
      return <Container>Placeholder name</Container>;
    case ProtectorType.User:
      return <Container>Select a user</Container>;
    case ProtectorType.Location:
      return <Container>Enter a location or address</Container>;
    case ProtectorType.Date:
      return <Container>DD/MM/YYYY</Container>;
    case ProtectorType.DateTime:
      return <Container>DD/MM/YYYY hh:mm aa</Container>;
    case ProtectorType.Time:
      return <Container>hh:mm aa</Container>;

    case ProtectorType.Lookup:
      return (
        <StyledSelect
          displayEmpty
          renderValue={(value: unknown) => {
            if (!value) {
              return <span>Related to</span>;
            }
            return <span>{value as string}</span>;
          }}
          fullWidth
          placeholder="Related to"
          value={lookupValue}
          size="small"
          style={{ height: '40px' }}
          onChange={(e) => {
            setLookupValue(e.target.value as string);
            onSettingLookup(e.target.value as string);
          }}
        >
          {activeEntityModules?.map((type) => (
            <MenuItem key={type} value={type}>
              {getEntityTranslation[type].singular}
            </MenuItem>
          ))}
        </StyledSelect>
      );
    default:
      return <Container>Unknown</Container>;
  }
};

export default EditComponentForProtectorType;
