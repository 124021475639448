import React, { useState } from 'react';

import {
  DndContext,
  DragOverlay,
  MouseSensor,
  TouchSensor,
  UniqueIdentifier,
  useSensors,
  useSensor,
  MeasuringStrategy,
  Over,
} from '@dnd-kit/core';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { observer } from 'mobx-react';

import { Button } from 'vatix-ui/lib/components/Button';

import { StyledTextField } from 'components/Input/styles';

import { useStore } from 'utils/hooks/store';

import Container from '../Container';

import { Items, dropAnimation } from './types';

import { DraggableItem, Item, SortableItem } from '../Item/Item';

import { EmptyContainer } from './styles';
import WrapperWithHeader from '../WrapperWithHeader/WrapperWithHeader';
import { DroppableContainer } from '../Container/Container';
import EmptyLayout from '../helpers/EmptyLayout';

const MultipleContainers = (): React.ReactElement => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [distanceFromTop, setDistanceFromTop] = useState(0);

  React.useEffect(() => {
    const calculateDistance = (): void => {
      if (containerRef.current) {
        const rect = containerRef.current.getBoundingClientRect();

        setDistanceFromTop(rect.top / 2 + 20);
      }
    };

    calculateDistance();
  }, []);

  const { entityFields, entityLayout } = useStore();

  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const [clonedItems, setClonedItems] = useState<Items | null>(null);

  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const onDragCancel = (): void => {
    if (clonedItems) {
      entityLayout.setItems(clonedItems);
    }

    setActiveId(null);
    setClonedItems(null);
  };

  function handleAddSection(): void {
    entityLayout.addSection();
  }

  const onRemove = (containerId: UniqueIdentifier): void => {
    entityFields.addAllFieldsFromRemovedContainer(entityLayout.items[containerId] as string[]);
    entityLayout.removeSection(containerId.toLocaleString());
  };

  return (
    <DndContext
      sensors={sensors}
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.Always,
        },
      }}
      onDragStart={({ active }) => {
        setActiveId(active.id);
        setClonedItems(entityLayout.items);
      }}
      onDragOver={({ active, over }) => {
        const overId = over?.id;
        if (overId == null || active.id in entityLayout.items) {
          return;
        }

        const overContainer = entityLayout.findItem(overId as string);
        const activeContainer = entityLayout.findItem(active.id as string);

        if (!overContainer && overId === 'fields_container' && activeContainer) {
          entityFields.addField(active.id as string);
          entityLayout.modifyItemsInContainer(
            activeContainer as string,
            entityLayout.items[activeContainer].filter((id) => id !== active.id) as string[]
          );
          return;
        }

        if (overContainer && !activeContainer) {
          entityFields.removeField(active.id as string);
          entityLayout.modifyItemsInContainer(
            overContainer as string,
            [...entityLayout.items[overContainer], active.id] as string[]
          );
          return;
        }

        if (!overContainer || !activeContainer) {
          return;
        }

        if (activeContainer !== overContainer) {
          entityLayout.moveItemsBetweenContainers(
            activeContainer as string,
            overContainer as string,
            overId as string,
            active,
            over as Over
          );
        }
        entityFields.searchFields('');
      }}
      onDragEnd={({ active, over }) => {
        if (active.id in entityLayout.items && over?.id) {
          entityLayout.reorderSections(active.id.toString(), over.id.toString());
        }

        const activeContainer = entityLayout.findItem(active.id as string);
        if (active.id === 'fields_container') {
          setActiveId(null);
          return;
        }

        if (!activeContainer) {
          setActiveId(null);
          return;
        }

        const overId = over?.id;

        if (overId == null) {
          setActiveId(null);
          return;
        }

        const overContainer = entityLayout.findItem(overId as string);

        if (overContainer) {
          const activeIndex = entityLayout.items[activeContainer].indexOf(active.id);
          const overIndex = entityLayout.items[overContainer].indexOf(overId);

          if (activeIndex !== overIndex) {
            entityLayout.modifyItemsInContainer(
              overContainer as string,
              arrayMove(entityLayout.items[overContainer], activeIndex, overIndex) as string[]
            );
          }
        }

        setActiveId(null);
      }}
      onDragCancel={onDragCancel}
    >
      <div style={{ display: 'flex', gap: '16px' }} ref={containerRef}>
        <WrapperWithHeader title="Fields" flex={1} distance={distanceFromTop}>
          <StyledTextField
            size="small"
            placeholder="Search..."
            value={entityFields.searchQuery}
            onChange={(event) => {
              entityFields.searchFields(event.target.value);
            }}
            style={{ padding: '12px 16px', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
          />
          <div
            style={{
              overflowY: 'auto',
              maxHeight: `calc(100vh - ${distanceFromTop}px)`,
            }}
          >
            <DroppableContainer
              key="fields_container"
              id="fields_container"
              disableActionsHeader
              disabled={false}
              items={entityFields.filteredData?.map(({ id }) => id) ?? []}
              withoutHeader
            >
              {(entityFields.filteredData || []).map(({ name, id }) => (
                <DraggableItem id={id as string} name={name} key={id} disabled={id.toString().includes('-disabled')} />
              ))}
            </DroppableContainer>
          </div>
        </WrapperWithHeader>
        <WrapperWithHeader
          title="Layout"
          flex={2}
          helpCenterRedirect
          unsavedChanges={entityLayout.contentOfLayoutWasEdited}
          distance={distanceFromTop}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              overflowY: 'auto',
              maxHeight: `calc(100vh - ${distanceFromTop}px)`,
            }}
          >
            <SortableContext strategy={verticalListSortingStrategy} items={[...entityLayout.containers]}>
              {entityLayout.items === null ||
              Object.keys(entityLayout.items).length === 0 ||
              !entityLayout.items ||
              Number(entityLayout.items.length) === 0 ||
              entityLayout.containers.length === 0 ? (
                <EmptyLayout />
              ) : (
                entityLayout.containers.map((containerId) => (
                  <DroppableContainer
                    key={containerId}
                    id={containerId}
                    label={entityLayout.getSectionTitle(containerId)}
                    onRemove={() => onRemove(containerId)}
                    items={entityLayout.items[containerId]}
                  >
                    <SortableContext items={entityLayout.items[containerId]}>
                      {entityLayout.items[containerId].length === 0 ? (
                        <EmptyContainer>
                          <InfoOutlinedIcon />
                          Drag & Drop first field to add content
                        </EmptyContainer>
                      ) : null}
                      {entityLayout.items[containerId].map((value, index) => (
                        <SortableItem
                          disabled={false}
                          key={value}
                          id={value}
                          name={entityFields.getNameOfField(value as string)}
                          index={index}
                          isLast={index === entityLayout.items[containerId].length - 1}
                        />
                      ))}
                    </SortableContext>
                  </DroppableContainer>
                ))
              )}

              <Button
                size="large"
                variant="outlined"
                style={{ margin: '0 16px 16px' }}
                disabled={false}
                onClick={handleAddSection}
              >
                Add section +
              </Button>
            </SortableContext>
            <DragOverlay
              adjustScale={false}
              dropAnimation={dropAnimation}
              // setting maxWidth to 200px to prevent the overlay from taking the whole screen,
              // needed for the drag and drop between containers and fields list
              style={{ maxWidth: activeId && entityLayout.containers.includes(activeId.toString()) ? '' : '200px' }}
            >
              {activeId &&
                (entityLayout.containers.includes(activeId.toString()) ? (
                  <Container
                    label={entityLayout.getSectionTitle(activeId.toString())}
                    isActive
                    id={activeId.toString()}
                  >
                    {entityLayout.items[activeId].map((item) => (
                      <Item key={item} value={entityFields.getNameOfField(item as string)} handle={false} />
                    ))}
                  </Container>
                ) : (
                  <Item value={entityFields.getNameOfField(activeId as string)} handle={false} dragOverlay />
                ))}
            </DragOverlay>
          </div>
        </WrapperWithHeader>
      </div>
    </DndContext>
  );
};

export default observer(MultipleContainers);
