/* eslint-disable max-len */
import * as React from 'react';

function DateIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M6 7.33301H4.66667V8.66634H6V7.33301ZM8.66667 7.33301H7.33333V8.66634H8.66667V7.33301ZM11.3333 7.33301H10V8.66634H11.3333V7.33301ZM12.6667 2.66634H12V1.33301H10.6667V2.66634H5.33333V1.33301H4V2.66634H3.33333C2.59333 2.66634 2.00667 3.26634 2.00667 3.99967L2 13.333C2 14.0663 2.59333 14.6663 3.33333 14.6663H12.6667C13.4 14.6663 14 14.0663 14 13.333V3.99967C14 3.26634 13.4 2.66634 12.6667 2.66634ZM12.6667 13.333H3.33333V5.99967H12.6667V13.333Z"
        fill="black"
        fillOpacity="0.54"
      />
    </svg>
  );
}

export default DateIcon;
