import React from 'react';
import { observer } from 'mobx-react';
import { Collapse, Grid } from '@mui/material';

import { ExpandMore, ExpandLess, Edit } from '@mui/icons-material';

import { Button } from 'vatix-ui/lib/components/Button';

import { SectionGrid } from 'components/BackgroundInformation/styles';
import { useStore } from 'utils/hooks/store';
import { AnswerType, IncidentResponse, JSONSchemaType, ProtectorType, SchemaContent } from 'utils/api/types';

import UserRow from 'components/UserRow';

import { EntityModules, UserRole } from 'core/constants';

import EntityDisplayValue from 'components/Entities/EntityDisplayValue/EntityDisplayValue';

import { useEntityDisplayValue } from 'components/Entities/EntityDisplayValue/utils';

import {
  StyledName,
  SectionContainer,
  BackgroundInformationSections,
  RowWrapper,
  RowContainer,
  StyledDivider,
} from './styles';

import EditIncidentForm from './components/EditIncidentForm';
import Row from './components/Row';
import CustomFieldDisplayValue from '../CustomFieldDisplayValue';

import NoneEditableValues from './components/NoneEditableValues';
import { Section, SectionContainerHeader, Title, TitleWrapper } from '../CustomFieldContainer/styles';
import { EmptyValue } from '../CustomFieldDisplayValue/styles';
import CustomFieldContainer from '../CustomFieldContainer';

const IncidentInformation: React.FunctionComponent<{
  data: SchemaContent;
}> = ({ data: { schema, content } }): React.ReactElement => {
  const {
    incidentDetails: { details: detailsRaw, updateIncidentData },
    session,
  } = useStore();
  const details = detailsRaw as IncidentResponse;

  const [keyFieldsEditing, setKeyFieldsEditing] = React.useState(false);
  const [keyFieldsExpanded, setKeyFieldsExpanded] = React.useState(true);

  const handleExpandClick = (): void => {
    setKeyFieldsExpanded((prevState) => !prevState);
  };

  const isValidProperty = (orderItem: string): boolean => {
    const isDefined = schema.properties[orderItem] !== undefined;
    if (!isDefined && session?.user?.role === UserRole.Admin) {
      // information for admins that schema is not valid
      // eslint-disable-next-line no-console
      console.error('undefined properties', orderItem);
    }
    return isDefined;
  };

  return (
    <BackgroundInformationSections key={`Incident-information-${details.owner}`}>
      <Section>
        <SectionContainerHeader $expanded={keyFieldsExpanded}>
          <TitleWrapper onClick={handleExpandClick}>
            {keyFieldsExpanded ? (
              <ExpandLess aria-labelledby="show less" fillOpacity="0.54" />
            ) : (
              <ExpandMore aria-labelledby="show more" fillOpacity="0.54" />
            )}
            <Title id="section-description">Key Fields</Title>
          </TitleWrapper>
          <SectionGrid item container xs={2} justifyContent="flex-end">
            {!session?.user?.readOnly && !keyFieldsEditing && (
              <Button
                onClick={() => {
                  setKeyFieldsEditing((prevState) => !prevState);
                }}
                id="edit-incident-button"
              >
                <Edit style={{ width: 20, height: 20, marginRight: '8px' }} />
                Edit
              </Button>
            )}
          </SectionGrid>
        </SectionContainerHeader>
        <Collapse in={keyFieldsExpanded} style={{ padding: '0 16px' }}>
          <SectionContainer>
            <Grid container spacing={0}>
              {keyFieldsEditing ? (
                <EditIncidentForm setEditing={setKeyFieldsEditing} />
              ) : (
                <>
                  <RowWrapper>
                    <RowContainer>
                      <Row name="Name" id="incident-owner-field">
                        <StyledName id="incident-subject">{details.subject}</StyledName>
                      </Row>
                    </RowContainer>
                    <StyledDivider />
                  </RowWrapper>

                  <RowWrapper>
                    <RowContainer>
                      <Row name="Owner" id="incident-owner-field">
                        {details.owner ? <UserRow user={details.owner} /> : <EmptyValue>-</EmptyValue>}
                      </Row>
                    </RowContainer>
                    <StyledDivider />
                  </RowWrapper>

                  <NoneEditableValues details={details} />

                  {session.user?.activeEntityModules
                    // filter out event module
                    .filter((val) => val !== EntityModules.Events)
                    .map((module) => {
                      const { path, entityLink, singular } = useEntityDisplayValue(module, details.links);
                      return (
                        <RowWrapper>
                          <RowContainer>
                            <Row name={singular} id={`related-${singular}-label`}>
                              {entityLink ? (
                                <EntityDisplayValue link={entityLink.instance.name} path={path} />
                              ) : (
                                <EmptyValue>-</EmptyValue>
                              )}
                            </Row>
                          </RowContainer>
                          <StyledDivider />
                        </RowWrapper>
                      );
                    })}

                  {schema.order
                    .filter(
                      (orderItem) =>
                        isValidProperty(orderItem) &&
                        schema.properties[orderItem].protectorType !== ProtectorType.Section
                    )
                    .map((orderItem, index) => {
                      const { protectorType, description: fieldDescription } = schema.properties[orderItem];
                      return (
                        <RowWrapper key={`field-description-${orderItem}`}>
                          <RowContainer>
                            <Row name={fieldDescription} id="field-description">
                              <CustomFieldDisplayValue
                                type={protectorType as ProtectorType}
                                value={content[orderItem]}
                                path={[orderItem]}
                                schema={(schema.properties || {})[orderItem]}
                                lookupType={schema.properties[orderItem].lookupType}
                              />
                            </Row>
                          </RowContainer>
                          {index !== schema.order.length - 1 && <StyledDivider />}
                        </RowWrapper>
                      );
                    })}
                </>
              )}
            </Grid>
          </SectionContainer>
        </Collapse>
      </Section>
      {schema.order
        .filter(
          (orderItem) =>
            isValidProperty(orderItem) && schema.properties[orderItem].protectorType === ProtectorType.Section
        )
        .map((orderItem) => (
          <CustomFieldContainer
            content={content[orderItem] as AnswerType}
            key={orderItem}
            onUpdate={async (value) =>
              updateIncidentData({
                details: {
                  schema,
                  content: {
                    ...content,
                    [orderItem]: {
                      ...value,
                    },
                  },
                },
              })
            }
            schema={schema.properties?.[orderItem] as JSONSchemaType}
          />
        ))}
    </BackgroundInformationSections>
  );
};

export default observer(IncidentInformation);
