import React from 'react';

import { MainHeader } from 'vatix-ui/lib/components/MainHeader/MainHeader';

import { Button } from 'vatix-ui/lib/components/Button';

import routes from 'core/routes';

import CreateFormModal from '../../CreateFormModal';

const Forms = (): React.ReactElement => {
  const [open, setOpen] = React.useState(false);

  const openModal = (): void => {
    setOpen(true);
  };

  return (
    <>
      <MainHeader
        title="Forms"
        breadcrumbs={[
          { label: 'Audits', link: routes.dashboard.audits.forms.toString() },
          { label: 'Forms', link: routes.dashboard.audits.forms.toString() },
        ]}
        action={
          <Button data-testid="new-form-btn" variant="contained" onClick={openModal} size="large">
            New
          </Button>
        }
      />
      <CreateFormModal open={open} setOpen={setOpen} />
    </>
  );
};

export default Forms;
