import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 194px;

  @media (max-width: 1280px) {
    margin: 0 97px;
  }
`;

export const FormNameTitle = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  margin-bottom: 4px;
`;
