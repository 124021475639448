import React from 'react';
import { MainHeader } from 'vatix-ui/lib/components/MainHeader/MainHeader';
import { Button } from 'vatix-ui/lib/components/Button';

import Record from 'vatix-ui/lib/containers/Record/Record';

import { reverse } from 'named-urls';

import { useRouteMatch } from 'react-router-dom';

import { observer } from 'mobx-react';

import { CenteredCircularProgress } from 'vatix-ui/lib/components/Progress';

import { formatDateTime } from 'vatix-ui/lib/utils/formatters/time';

import routes from 'core/routes';

import { useNavBarHeight } from 'utils/hooks/navbar';

import { getEntityTranslation } from 'stores/EntityDetails/utils';
import { EntityModules } from 'core/constants';

import { useStore } from 'utils/hooks/store';

import { LastPublished } from 'containers/LayoutEditor/styles';

import FormBuilderDragAndDrop from './components/FormBuilderDragAndDrop';
import { HeaderAction } from './styles';

const EntitiesFormBuilder = (): React.ReactElement => {
  const { formBuilder, routing } = useStore();
  const navBarHeight = useNavBarHeight();
  const match = useRouteMatch(routes.dashboard.objectManager.details.formBuilder.details.toString());

  const { moduleName, formId } = match?.params as { moduleName: string; formId: string };

  const [saving, setSaving] = React.useState(false);

  React.useEffect(() => () => formBuilder.resetForm(), []);

  React.useEffect(() => {
    const fetchData = async (): Promise<void> => {
      await formBuilder.loadLayout(moduleName, formId);
    };
    fetchData();
  }, []);

  if (formBuilder.isLoaded === false) {
    return <CenteredCircularProgress aria-label="form-builder-loading" />;
  }

  const onPublish = async (): Promise<void> => {
    setSaving(true);
    await formBuilder.saveForm();
    setSaving(false);
  };

  const onCancel = (): void => {
    formBuilder.resetForm();
    routing.push(reverse(routes.dashboard.objectManager.details.formBuilder.toString(), { moduleName }));
  };

  return (
    <Record
      disableMaxContentHeight
      navBarHeight={navBarHeight}
      Header={
        <MainHeader
          title="Form Builder"
          breadcrumbs={[
            {
              label: 'Object Manager',
              link: reverse(routes.dashboard.objectManager.details.fieldManager, { moduleName }),
            },
            {
              label: getEntityTranslation[moduleName as EntityModules].plural,
              link: reverse(routes.dashboard.objectManager.details.fieldManager, { moduleName }),
            },
            {
              label: 'Form Builder',
              link: reverse(routes.dashboard.objectManager.details.fieldManager, { moduleName }),
            },
          ]}
          action={
            <HeaderAction>
              {formBuilder.lastPublished !== undefined && (
                <LastPublished>{`Last published: ${formatDateTime(formBuilder.lastPublished)}`}</LastPublished>
              )}

              <Button onClick={onCancel} variant="outlined" size="large">
                Cancel
              </Button>
              <Button onClick={onPublish} variant="contained" size="large" disabled={saving}>
                Publish
              </Button>
            </HeaderAction>
          }
        />
      }
      Content={() => <FormBuilderDragAndDrop />}
    />
  );
};

export default observer(EntitiesFormBuilder);
