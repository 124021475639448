import React from 'react';

import ComponentForProtectorType from '../ComponentForProtectorType/ComponentForProtectorType';
import { DisplayFieldContainer, Title } from './styles';
import { DisplayFieldProps } from './types';

const DisplayField: React.FC<DisplayFieldProps> = ({
  description,
  questionType,
  isRequired,
  lookupData,
}): React.ReactElement => (
  <DisplayFieldContainer key={`display_container_${description}`}>
    <Title key={`title_${description}`}>
      {description || 'Untitled'}
      <span style={{ marginLeft: '4px', color: 'red' }}>{isRequired ? '*' : ''}</span>
    </Title>
    {questionType ? (
      <ComponentForProtectorType
        questionType={questionType}
        lookupData={lookupData}
        key={`${questionType}_${description}`}
      />
    ) : null}
  </DisplayFieldContainer>
);

export default DisplayField;
