/* eslint-disable max-len */
import * as React from 'react';

function LocationIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M8.0026 1.33301C5.4226 1.33301 3.33594 3.41967 3.33594 5.99967C3.33594 9.49967 8.0026 14.6663 8.0026 14.6663C8.0026 14.6663 12.6693 9.49967 12.6693 5.99967C12.6693 3.41967 10.5826 1.33301 8.0026 1.33301ZM8.0026 7.66634C7.0826 7.66634 6.33594 6.91967 6.33594 5.99967C6.33594 5.07967 7.0826 4.33301 8.0026 4.33301C8.9226 4.33301 9.66927 5.07967 9.66927 5.99967C9.66927 6.91967 8.9226 7.66634 8.0026 7.66634Z"
        fill="black"
        fillOpacity="0.54"
      />
    </svg>
  );
}

export default LocationIcon;
