/* eslint-disable max-len */
import * as React from 'react';

function LongTextIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <rect x="0.5" y="0.5" width="15" height="15" rx="4.2" stroke="black" strokeOpacity="0.54" />
      <path
        d="M11.3189 3.99902H4.30596C4.13698 3.99902 4 4.136 4 4.30499V6.14083C4 6.30981 4.13698 6.44679 4.30596 6.44679H4.91791C5.08689 6.44679 5.22387 6.30981 5.22387 6.14083V5.52888H7.04747V11.3424H6.28254C6.11356 11.3424 5.97658 11.4793 5.97658 11.6483V12.2603C5.97658 12.4292 6.11356 12.5662 6.28254 12.5662H9.34226C9.51124 12.5662 9.64822 12.4292 9.64822 12.2603V11.6483C9.64822 11.4793 9.51124 11.3424 9.34226 11.3424H8.57733V5.52888H10.4009V6.14083C10.4009 6.30981 10.5379 6.44679 10.7069 6.44679H11.3188C11.4878 6.44679 11.6248 6.30981 11.6248 6.14083V4.30499C11.6248 4.136 11.4878 3.99902 11.3189 3.99902Z"
        fill="black"
        fillOpacity="0.54"
      />
    </svg>
  );
}

export default LongTextIcon;
