/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { AbacProvider } from 'react-abac';
import { UuidableName } from 'vatix-ui/lib/utils/api/types';

import { UserRole } from 'core/constants';
import SessionUser from 'stores/Session/SessionUser';
import { useStore } from 'utils/hooks/store';
import { UserLicensesResponse } from 'utils/api/types';

import { SideBarPermissions, UserRoleRules } from './types';
import SideBar from '../SideBar';

const AllLicensesBlockedPermission = (licenses: UserLicensesResponse): boolean => {
  const {
    protectorAlarms,
    // our logic is not relying on these licenses
    risks,
    events,
    protectorMonitoringSelf,
    protectorMonitoringVatix,
    protectorNotify,
    ...otherLicenses
  } = licenses;

  return !(protectorAlarms && Object.values(otherLicenses).every((license) => license === true));
};

const UserHasNoLicenses = (licenses: UserLicensesResponse): boolean =>
  Object.values(licenses).every((license) => !license);

const LoneWorkerOnlyBlockedPermission = (licenses: UserLicensesResponse): boolean => {
  if (UserHasNoLicenses(licenses)) {
    return false;
  }

  const {
    protectorAlarms,
    // our logic is not relying on these licenses
    protectorMonitoringSelf,
    protectorMonitoringVatix,
    protectorNotify,
    ...otherLicenses
  } = licenses;
  if (protectorAlarms && Object.values(otherLicenses).every((license) => license === false)) {
    return false;
  }
  return true;
};

const LoneWorkerAllowedPermission = (licenses: UserLicensesResponse): boolean => licenses.protectorAlarms;

const IncidentsAllowedPermission = (licenses: UserLicensesResponse): boolean => licenses.protectorIncidents;

const WorkflowsAllowedPermission = (licenses: UserLicensesResponse): boolean => licenses.workflows;

const IncidentOrWorkflowsAllowedPermission = (licenses: UserLicensesResponse): boolean =>
  licenses.protectorIncidents || licenses.workflows;

const UserOrganisationActivityMapAllowedPermission = (user?: SessionUser): boolean =>
  Boolean(user?.organisation.activityMap);

const getCleanedDashboards = (user?: SessionUser): Record<string, UuidableName[]> =>
  Object.fromEntries(
    Object.entries(user?.dashboards || {}).filter(([, value]) => Array.isArray(value) && value.length > 0)
  );

const hasAnyDashboard = (licenses?: UserLicensesResponse, user?: SessionUser): boolean => {
  if (licenses && UserHasNoLicenses(licenses)) {
    return false;
  }
  const cleanedDashboards = getCleanedDashboards(user);
  return Boolean(user?.dashboards) && Object.keys(cleanedDashboards).length > 0;
};

const rules: UserRoleRules = {
  [UserRole.Admin]: {
    [SideBarPermissions.HOME_PAGE_VISIBLE]: true,
    [SideBarPermissions.INBOX_VISIBLE]: LoneWorkerOnlyBlockedPermission,
    [SideBarPermissions.ACTIONS_VISIBLE]: LoneWorkerOnlyBlockedPermission,
    [SideBarPermissions.INCIDENTS_VISIBLE]: IncidentsAllowedPermission,
    [SideBarPermissions.LONE_WORKING_VISIBLE]: LoneWorkerAllowedPermission,
    [SideBarPermissions.LONE_WORKING_MANAGE_VISIBLE]: true,
    [SideBarPermissions.LONE_WORKING_DEVICES_VISIBLE]: true,
    [SideBarPermissions.LONE_WORKING_USAGE_VISIBLE]: true,
    [SideBarPermissions.LONE_WORKING_ARPS_VISIBLE]: true,
    [SideBarPermissions.LONE_WORKING_MAP_VISIBLE]: UserOrganisationActivityMapAllowedPermission,
    [SideBarPermissions.AUDITS_VISIBLE]: WorkflowsAllowedPermission,
    [SideBarPermissions.ANALYTICS_VISIBLE]: (data?: { licenses: UserLicensesResponse; user?: SessionUser }) =>
      hasAnyDashboard(data?.licenses, data?.user),
    [SideBarPermissions.ADMIN_VISIBLE]: true,
    [SideBarPermissions.PROFILE_SETTINGS_NOTIFICATIONS_VISIBLE]: IncidentOrWorkflowsAllowedPermission,
    [SideBarPermissions.MORE_APPS_VISIBLE]: AllLicensesBlockedPermission,
  },
  [UserRole.AccountManager]: {
    [SideBarPermissions.HOME_PAGE_VISIBLE]: true,
    [SideBarPermissions.INBOX_VISIBLE]: LoneWorkerOnlyBlockedPermission,
    [SideBarPermissions.ACTIONS_VISIBLE]: LoneWorkerOnlyBlockedPermission,
    [SideBarPermissions.INCIDENTS_VISIBLE]: IncidentsAllowedPermission,
    [SideBarPermissions.LONE_WORKING_VISIBLE]: LoneWorkerAllowedPermission,
    [SideBarPermissions.LONE_WORKING_MANAGE_VISIBLE]: true,
    [SideBarPermissions.LONE_WORKING_DEVICES_VISIBLE]: true,
    [SideBarPermissions.LONE_WORKING_USAGE_VISIBLE]: true,
    [SideBarPermissions.LONE_WORKING_ARPS_VISIBLE]: true,
    [SideBarPermissions.LONE_WORKING_MAP_VISIBLE]: UserOrganisationActivityMapAllowedPermission,
    [SideBarPermissions.AUDITS_VISIBLE]: WorkflowsAllowedPermission,
    [SideBarPermissions.ANALYTICS_VISIBLE]: (data?: { licenses: UserLicensesResponse; user?: SessionUser }) =>
      hasAnyDashboard(data?.licenses, data?.user),
    [SideBarPermissions.ADMIN_VISIBLE]: true,
    [SideBarPermissions.PROFILE_SETTINGS_NOTIFICATIONS_VISIBLE]: IncidentOrWorkflowsAllowedPermission,
    [SideBarPermissions.MORE_APPS_VISIBLE]: AllLicensesBlockedPermission,
  },
  [UserRole.Manager]: {
    [SideBarPermissions.HOME_PAGE_VISIBLE]: true,
    [SideBarPermissions.INBOX_VISIBLE]: LoneWorkerOnlyBlockedPermission,
    [SideBarPermissions.ACTIONS_VISIBLE]: LoneWorkerOnlyBlockedPermission,
    [SideBarPermissions.INCIDENTS_VISIBLE]: IncidentsAllowedPermission,
    [SideBarPermissions.LONE_WORKING_VISIBLE]: LoneWorkerAllowedPermission,
    [SideBarPermissions.LONE_WORKING_MANAGE_VISIBLE]: true,
    [SideBarPermissions.LONE_WORKING_DEVICES_VISIBLE]: true,
    [SideBarPermissions.LONE_WORKING_USAGE_VISIBLE]: true,
    [SideBarPermissions.LONE_WORKING_ARPS_VISIBLE]: true,
    [SideBarPermissions.LONE_WORKING_MAP_VISIBLE]: UserOrganisationActivityMapAllowedPermission,
    [SideBarPermissions.AUDITS_VISIBLE]: WorkflowsAllowedPermission,
    [SideBarPermissions.ANALYTICS_VISIBLE]: (data?: { licenses: UserLicensesResponse; user?: SessionUser }) =>
      hasAnyDashboard(data?.licenses, data?.user),
    [SideBarPermissions.ADMIN_VISIBLE]: false,
    [SideBarPermissions.PROFILE_SETTINGS_NOTIFICATIONS_VISIBLE]: IncidentOrWorkflowsAllowedPermission,
    [SideBarPermissions.MORE_APPS_VISIBLE]: AllLicensesBlockedPermission,
  },
  [UserRole.ArcOperator]: {
    [SideBarPermissions.HOME_PAGE_VISIBLE]: false,
    [SideBarPermissions.INBOX_VISIBLE]: LoneWorkerOnlyBlockedPermission,
    [SideBarPermissions.ACTIONS_VISIBLE]: LoneWorkerOnlyBlockedPermission,
    [SideBarPermissions.INCIDENTS_VISIBLE]: IncidentsAllowedPermission,
    [SideBarPermissions.LONE_WORKING_VISIBLE]: LoneWorkerAllowedPermission,
    [SideBarPermissions.LONE_WORKING_MANAGE_VISIBLE]: false,
    [SideBarPermissions.LONE_WORKING_DEVICES_VISIBLE]: false,
    [SideBarPermissions.LONE_WORKING_USAGE_VISIBLE]: false,
    [SideBarPermissions.LONE_WORKING_ARPS_VISIBLE]: false,
    [SideBarPermissions.LONE_WORKING_MAP_VISIBLE]: UserOrganisationActivityMapAllowedPermission,
    [SideBarPermissions.AUDITS_VISIBLE]: WorkflowsAllowedPermission,
    [SideBarPermissions.ANALYTICS_VISIBLE]: false,
    [SideBarPermissions.ADMIN_VISIBLE]: false,
    [SideBarPermissions.PROFILE_SETTINGS_NOTIFICATIONS_VISIBLE]: IncidentOrWorkflowsAllowedPermission,
    [SideBarPermissions.MORE_APPS_VISIBLE]: false,
  },
  [UserRole.User]: {
    [SideBarPermissions.HOME_PAGE_VISIBLE]: true,
    [SideBarPermissions.INBOX_VISIBLE]: LoneWorkerOnlyBlockedPermission,
    [SideBarPermissions.ACTIONS_VISIBLE]: LoneWorkerOnlyBlockedPermission,
    [SideBarPermissions.INCIDENTS_VISIBLE]: IncidentsAllowedPermission,
    [SideBarPermissions.LONE_WORKING_VISIBLE]: false,
    [SideBarPermissions.LONE_WORKING_MANAGE_VISIBLE]: false,
    [SideBarPermissions.LONE_WORKING_DEVICES_VISIBLE]: false,
    [SideBarPermissions.LONE_WORKING_USAGE_VISIBLE]: false,
    [SideBarPermissions.LONE_WORKING_ARPS_VISIBLE]: false,
    [SideBarPermissions.LONE_WORKING_MAP_VISIBLE]: UserOrganisationActivityMapAllowedPermission,
    [SideBarPermissions.AUDITS_VISIBLE]: WorkflowsAllowedPermission,
    [SideBarPermissions.ANALYTICS_VISIBLE]: false,
    [SideBarPermissions.ADMIN_VISIBLE]: false,
    [SideBarPermissions.PROFILE_SETTINGS_NOTIFICATIONS_VISIBLE]: IncidentOrWorkflowsAllowedPermission,
    [SideBarPermissions.MORE_APPS_VISIBLE]: AllLicensesBlockedPermission,
  },
};

const SideBarPermissionsWrapper: React.FC = () => {
  const {
    session: { user },
  } = useStore();
  return (
    <AbacProvider roles={user ? [user.role] : []} permissions={[]} rules={rules}>
      <SideBar />
    </AbacProvider>
  );
};

export default SideBarPermissionsWrapper;
