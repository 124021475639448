import React from 'react';
import { MainHeader } from 'vatix-ui/lib/components/MainHeader/MainHeader';

import Record from 'vatix-ui/lib/containers/Record/Record';

import { reverse } from 'named-urls';

import { useRouteMatch } from 'react-router-dom';

import { GridEventListener, GridRowParams } from '@mui/x-data-grid';

import { Button } from 'vatix-ui/lib/components/Button';

import routes from 'core/routes';

import { useNavBarHeight } from 'utils/hooks/navbar';

import { getEntityTranslation } from 'stores/EntityDetails/utils';
import { EntityModules } from 'core/constants';
import Grid from 'components/Grid';
import { ColumnDefinition } from 'components/Grid/types';
import { useStore } from 'utils/hooks/store';

export const basicColumns = ([
  {
    field: 'name',
    headerName: 'Form',
    sortable: false,
  },
] as unknown) as ColumnDefinition[];

const EventForms = (): React.ReactElement => {
  const { routing } = useStore();
  const navBarHeight = useNavBarHeight();
  const match = useRouteMatch(routes.dashboard.objectManager.details.formBuilder.toString());

  const { moduleName } = match?.params as { moduleName: string };

  const onRowClick: GridEventListener<'rowClick'> = (form: GridRowParams): void => {
    const path = reverse(routes.dashboard.objectManager.details.formBuilder.toString(), {
      moduleName: EntityModules.Events,
    });
    routing.push(`${path + form.row.uuid}/`);
  };

  return (
    <Record
      navBarHeight={navBarHeight}
      Header={
        <MainHeader
          title="Form Builder"
          breadcrumbs={[
            {
              label: 'Object Manager',
              link: reverse(routes.dashboard.objectManager.details.fieldManager, { moduleName }),
            },
            {
              label: getEntityTranslation[moduleName as EntityModules].plural,
              link: reverse(routes.dashboard.objectManager.details.fieldManager, { moduleName }),
            },
            {
              label: 'Forms',
              link: reverse(routes.dashboard.objectManager.details.formBuilder.toString(), { moduleName }),
            },
          ]}
          action={
            <Button
              variant="contained"
              onClick={() => {
                const path = reverse(routes.dashboard.objectManager.details.formBuilder.toString(), {
                  moduleName: EntityModules.Events,
                });
                routing.push(`${path}new/`);
              }}
            >
              New
            </Button>
          }
        />
      }
      Content={() => (
        <Grid
          key={`form_builder_${moduleName}`}
          basicColumns={basicColumns}
          entity={`${moduleName} forms`}
          dataURL={`/api/entities/${moduleName}/forms`}
          onRowClick={onRowClick}
          showHeader={false}
          customNoRowsText="No forms have been created yet"
        />
      )}
    />
  );
};

export default EventForms;
