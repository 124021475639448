import { ColumnDefinition } from 'components/Grid/types';
import { basicAssetsColumns } from 'containers/Assets/components/AssetsTable/AssetsTable';
import { basicSiteColumns } from 'containers/Sites/components/SitesTable/SitesTable';
import { basicSuppliersColumns } from 'containers/Suppliers/components/SuppliersTable/SuppliersTable';
import { EntityModules } from 'core/constants';

export const getBasicColumnsBasedOnEntity = (entity: EntityModules): ColumnDefinition[] => {
  switch (entity) {
    case EntityModules.Sites:
      return basicSiteColumns;
    case EntityModules.Assets:
      return basicAssetsColumns;
    case EntityModules.Suppliers:
      return basicSuppliersColumns;
    case EntityModules.Events:
      // TODO: Update columns after implementing events data grid
      return [
        {
          field: `events__eventName`,
          headerName: 'Name',
          nativeFilter: true,
        },
        {
          field: 'type',
          headerName: 'Type',
          nativeFilter: true,
        },
        {
          field: 'status',
          headerName: 'Status',
          nativeFilter: true,
        },
      ] as ColumnDefinition[];
    default:
      return [];
  }
};
