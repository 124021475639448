import { styled } from 'styled-components';

export const Container = styled.div`
  display: flex;
  border: 1px solid #e7e7e7;
  border-radius: 4px;
  padding: 8px 12px;
  background-color: #fff;
  justify-content: space-between;
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  width: 100%;
`;
