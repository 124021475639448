import { styled } from 'styled-components';

export const Container = styled.div`
  display: flex;
  border: 1px solid #e7e7e7;
  border-radius: 4px;
  padding: 8px 12px;
  background-color: #fff;
  justify-content: space-between;
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  width: 100%;
`;

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #0772e5;
  margin: 4px 0;
  background: #f5f5f5;
`;

export const FieldProperties = styled.div`
  background: #fff;
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 8px;
  padding: 8px;
  justify-content: flex-end;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  gap: 8px;
  padding: 8px 24px;
`;
