import React from 'react';

import { Grid } from '@mui/material';

import IncidentFormField from 'containers/IncidentDetails/components/IncidentFormField/IncidentFormField';

import { AnswerTypes, JSONSchemaType, ProtectorType } from 'utils/api/types';

import { StyledImage } from 'containers/IncidentDetails/components/CustomImage/styles';

import { useStore } from 'utils/hooks/store';

import FieldTitle from '../FieldTitle';

const IncidentForm: React.FunctionComponent<{
  editable?: boolean;
  properties: JSONSchemaType;
  required: boolean;
  title: string;
  updateField: (field: string, value: AnswerTypes) => void;
  incidentContent: AnswerTypes;
}> = ({ editable, required, title, updateField, incidentContent, properties }): React.ReactElement => {
  const {
    incidentDetails: { missingRequiredValues },
  } = useStore();

  return (
    <div style={{ paddingBottom: '10px' }}>
      <Grid item container xs={12} alignContent="flex-start">
        <FieldTitle description={properties.description} required={required} />
      </Grid>
      <Grid item container xs={12}>
        {(properties?.protectorType as ProtectorType) !== ProtectorType.Image && properties.contentEncoding ? (
          <StyledImage src={properties.contentEncoding} alt={`img-${properties.description}`} />
        ) : null}
        <IncidentFormField
          requiredNotFilled={missingRequiredValues.includes(title)}
          editable={editable}
          value={incidentContent || ''}
          onChange={(value) => updateField(title, value)}
          type={properties?.protectorType as ProtectorType}
          items={properties?.items}
          description={properties.description}
          contentEncoding={properties.contentEncoding}
          schema={properties}
          lookupType={properties.lookupType}
        />
      </Grid>
    </div>
  );
};

export default IncidentForm;
