import React, { memo } from 'react';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DateRangeIcon from '@mui/icons-material/DateRange';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import UnfoldMoreOutlinedIcon from '@mui/icons-material/UnfoldMoreOutlined';

import { ProtectorType } from 'utils/api/types';
import { getEntityTranslation } from 'stores/EntityDetails/utils';
import { EntityModules } from 'core/constants';

import { Container } from './styles';

type ComponentForProtectorTypeProps = {
  questionType: ProtectorType;
  lookupData?: string;
};

const getAdornmentIcon = (type: ProtectorType): JSX.Element | null => {
  const htmlColor = 'rgba(0, 0, 0, 0.54)';
  switch (type) {
    case ProtectorType.Number:
      return <UnfoldMoreOutlinedIcon htmlColor={htmlColor} />;
    case ProtectorType.Date:
    case ProtectorType.DateTime:
      return <DateRangeIcon htmlColor={htmlColor} />;
    case ProtectorType.Time:
      return <AccessTimeIcon htmlColor={htmlColor} />;
    case ProtectorType.User:
    case ProtectorType.Lookup:
      return <KeyboardArrowDownIcon htmlColor={htmlColor} />;
    case ProtectorType.Location:
      return <LocationOnIcon htmlColor={htmlColor} />;
    default:
      return null;
  }
};

const getContent = (questionType: ProtectorType, lookupData?: string): string => {
  switch (questionType) {
    case ProtectorType.ShortText:
      return 'Short-text area';
    case ProtectorType.LongText:
      return 'Long-text area';
    case ProtectorType.Number:
      return 'Number';
    case ProtectorType.Date:
      return 'DD/MM/YYYY';
    case ProtectorType.Time:
      return 'hh:mm aa';
    case ProtectorType.DateTime:
      return 'DD/MM/YYYY hh:mm aa';
    case ProtectorType.User:
      return 'Select a user';
    case ProtectorType.Location:
      return 'Enter a location or address';
    case ProtectorType.Lookup:
      return lookupData ? getEntityTranslation[lookupData as EntityModules].plural : 'Choose an option';
    default:
      return 'Unknown';
  }
};

const ComponentForProtectorType: React.FC<ComponentForProtectorTypeProps> = memo(({ questionType, lookupData }) => {
  const Icon = getAdornmentIcon(questionType);
  const content = getContent(questionType, lookupData);

  return (
    <Container $height={questionType === ProtectorType.LongText ? 104 : undefined}>
      {content}
      {Icon}
    </Container>
  );
});

export default ComponentForProtectorType;
