import styled from 'styled-components';

export const OptionContainer = styled.div`
  border: 1px solid #ccc;
  background: #fcfcfc;
  border-radius: 4px;
  padding: 8px 12px;
  margin: 8px 0;
`;

export const OptionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 4px;

  p {
    color: rgba(0, 0, 0, 0.87);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
    margin: 0;
  }
`;
