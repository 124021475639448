import React from 'react';

import { UniqueIdentifier } from '@dnd-kit/core';
import {
  AnimateLayoutChanges,
  defaultAnimateLayoutChanges,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import { Collapse, Tooltip } from '@mui/material';

import { Add, ArrowForward } from '@mui/icons-material';

import { Button } from 'vatix-ui/lib/components/Button';

import { useStore } from 'utils/hooks/store';

import SortableItem from '../Items/Items';
import { SectionContainer, SectionLogic } from './styles';
import SectionHeader from './components/SectionHeader';

const animateLayoutChanges: AnimateLayoutChanges = (args) =>
  defaultAnimateLayoutChanges({ ...args, wasDragging: true });

const Section: React.FC<{ id: UniqueIdentifier; items: string[] }> = ({ id, items }): React.ReactElement => {
  const { formBuilder } = useStore();

  const { setNodeRef } = useSortable({
    id,
    data: {
      type: 'container',
      children: items,
    },
    animateLayoutChanges,
  });

  const [expanded, setExpanded] = React.useState(true);

  const addQuestion = (): void => {
    formBuilder.addQuestionToSection(id as string);
  };

  return (
    <SortableContext strategy={verticalListSortingStrategy} id={id as string} items={items}>
      <SectionContainer ref={setNodeRef}>
        <SectionHeader id={id} setExpanded={setExpanded} expanded={expanded} />
        <Collapse in={expanded} key={`${id}_collapse`} style={{ padding: expanded ? '16px' : '', height: 'auto' }}>
          {items.map((item) => (
            <SortableItem key={item} sectionId={id} fieldId={item} />
          ))}
          <Button
            onClick={addQuestion}
            style={{ marginLeft: '24px' }}
            size="small"
            variant="text"
            startIcon={<Add style={{ width: '16px', height: '16px' }} />}
          >
            Add Question
          </Button>
        </Collapse>
      </SectionContainer>
      <Tooltip title="Not ready yet, to be implemented" placement="bottom">
        <SectionLogic>
          <p>End of the section</p>
          <ArrowForward style={{ width: '16px', height: '16px' }} />
          <p>SubmitForm</p>
        </SectionLogic>
      </Tooltip>
    </SortableContext>
  );
};

export default Section;
