/* eslint-disable max-len */
import * as React from 'react';

function NumberIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path d="M2 5H14V6.5H2V5Z" fill="black" fillOpacity="0.54" />
      <path d="M2 9.5H14V11H2V9.5Z" fill="black" fillOpacity="0.54" />
      <path
        d="M8.67969 15.792L10.3417 0.0427583L11.8342 0.200258L10.1722 15.9495L8.67969 15.792Z"
        fill="black"
        fillOpacity="0.54"
      />
      <path
        d="M4.17969 15.7949L5.84167 0.045688L7.33417 0.203188L5.67219 15.9524L4.17969 15.7949Z"
        fill="black"
        fillOpacity="0.54"
      />
    </svg>
  );
}

export default NumberIcon;
