import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';

import { useRouteMatch } from 'react-router-dom';

import { reverse } from 'named-urls';

import Record from 'vatix-ui/lib/containers/Record/Record';

import { MainHeader } from 'vatix-ui/lib/components/MainHeader/MainHeader';

import { Button } from 'vatix-ui/lib/components/Button';

import { GridValidRowModel } from '@mui/x-data-grid';

import { CenteredCircularProgress } from 'vatix-ui/lib/components/Progress';

import dayjs from 'dayjs';

import routes from 'core/routes';

import { useStore } from 'utils/hooks/store';

import { useNavBarHeight } from 'utils/hooks/navbar';

import api from 'utils/api';

import Grid from 'components/Grid/Grid';

import { TeamAssignedUserResponse, TeamDetailsResponse } from 'utils/api/types';

import { Container, SectionHeader, TextInput } from './styles';

import AddMembers from './components/AddMembers';
import { basicColumns } from './TeamDetailsColumns';

const TeamsDetails: React.FunctionComponent = () => {
  const navBarHeight = useNavBarHeight();
  const { routing, notification } = useStore();
  const [isNew, setIsNew] = useState(true);
  const match = useRouteMatch();

  const { teamId } = match.params as { teamId: string };

  useEffect(() => {
    setIsNew(match.url === routes.dashboard.admin.teams.new);
  }, [match.url]);

  React.useEffect(() => {
    (async () => {
      if (teamId) {
        const res = await api.loadTeamDetails(teamId)();
        setResult(res.data);
      }
    })();
  }, []);

  const onCancel = (): void => {
    routing.push(reverse(routes.dashboard.admin.teams.toString()));
  };

  const [results, setResult] = useState<TeamDetailsResponse>(
    isNew
      ? {
          uuid: '',
          name: `Team created ${dayjs(new Date()).format('DD MMM HH:mm:ss')}`,
          members: [],
        }
      : {}
  );

  const onSave = async (): Promise<void> => {
    try {
      const teamValues = { name: results?.name || 'default name', members: results?.members || [] };
      if (isNew) {
        await api.createTeam(teamValues)();
        notification.enqueueSuccessSnackbar(`Team created successfully.`);
      } else {
        await api.updateTeam(teamId, teamValues)();
        notification.enqueueSuccessSnackbar(`Team updated successfully.`);
      }
      routing.push(reverse(routes.dashboard.admin.teams.toString()));
    } catch (error) {
      notification.enqueueErrorSnackbar(`Team cannot be ${isNew ? 'created' : 'updated'} right now.`);
    }
  };

  const addMembers = (members: TeamAssignedUserResponse[]): void =>
    setResult((prevState) => ({
      ...prevState,
      members: [...(prevState?.members || []), ...members],
    }));

  const transformData = (
    response: Response
  ): {
    count: number;
    results: GridValidRowModel[];
  } => ({
    // @ts-ignore
    count: Object.keys(response.members).length,
    // @ts-ignore
    results: response.members.map((m) => ({
      ...m,
      setAdmin: (isAdmin: boolean) =>
        setResult((prevState) => ({
          ...prevState,
          members: (prevState?.members || []).map((i) => (i.uuid === m.uuid ? { ...i, isAdmin } : i)),
        })),
      removeMember: () =>
        setResult((prevState) => ({
          ...prevState,
          members: (prevState?.members || []).filter(({ uuid }) => uuid !== m.uuid),
        })),
    })),
  });

  if (!results) {
    return <CenteredCircularProgress />;
  }

  return (
    <Record
      disableMaxContentHeight
      navBarHeight={navBarHeight}
      Content={(onError) => (
        <Container>
          <SectionHeader>Team Name</SectionHeader>
          <TextInput
            fullWidth
            id="team-name"
            value={results.name}
            onChange={(e) => {
              setResult((prevState) => ({ ...prevState, name: e.target.value }));
            }}
          />
          <Grid
            onError={onError}
            dataURL=""
            // @ts-ignore
            initialData={transformData(results)}
            basicColumns={basicColumns}
            onRowClick={() => {}}
            disableColumnSelector
            disableColumnMenu
            transformData={transformData}
            showHeader={false}
            showQuickFilter={false}
            customNoRowsText="No users have been added"
          />
          <AddMembers addMembers={addMembers} membersInTeam={results.members || []} />
        </Container>
      )}
      Header={
        <MainHeader
          title={isNew ? 'Create New Team' : 'Edit Team'}
          breadcrumbs={[
            { label: 'Admin', link: routes.dashboard.admin.teams.toString() },
            { label: 'Teams', link: routes.dashboard.admin.teams.toString() },
            {
              label: isNew ? 'New Team' : 'Edit Team',
              link: isNew
                ? routes.dashboard.admin.teams.new.toString()
                : reverse(routes.dashboard.admin.teams.details, { teamId }),
            },
          ]}
          action={
            <div style={{ display: 'flex' }}>
              <Button onClick={onCancel} variant="outlined" size="large">
                Cancel
              </Button>
              <Button onClick={onSave} variant="contained" size="large" style={{ marginLeft: '16px' }}>
                Save
              </Button>
            </div>
          }
        />
      }
    />
  );
};

export default observer(TeamsDetails);
