import styled from 'styled-components';

export const ItemContainer = styled.div`
  display: flex;
  padding: 4px 12px;
  justify-content: space-between;
  align-items: center;
  flex: 1 1 0;
  background: #fff;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-left: 1px solid #ddd;
`;

export const InactiveItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px;
  padding-left: 12px;
  margin: 4px 0;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #ddd;
  color: rgba(0, 0, 0, 0.38);
  p {
    margin: 0;
    padding: 0;
  }
`;
