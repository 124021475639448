import React from 'react';
import { observer } from 'mobx-react';
import { reverse } from 'named-urls';
import { GridEventListener, GridRowParams } from '@mui/x-data-grid';

import { useRouteMatch } from 'react-router-dom';

import Record from 'vatix-ui/lib/containers/Record/Record';

import MainHeader from 'vatix-ui/lib/components/MainHeader';

import DataError from 'vatix-ui/lib/components/Error/DataError';

import routes from 'core/routes';
import { useStore } from 'utils/hooks/store';

import Grid from 'components/Grid';

import { useNavBarHeight } from 'utils/hooks/navbar';

import { getEntityTranslation } from 'stores/EntityDetails/utils';
import { EntityModules } from 'core/constants';

import { getBasicColumnsBasedOnEntity } from './utils';
import { getRelatedEntityDetails } from '../helper';

const RelatedEntities: React.FunctionComponent = () => {
  const { routing } = useStore();
  const navBarHeight = useNavBarHeight();

  const onRowClick: GridEventListener<'rowClick'> = (entity: GridRowParams): void => {
    routing.push(reverse(routes.dashboard[entityType].details, { entityId: entity.row.uuid }));
  };

  const { params, url } = useRouteMatch();
  const { entityId } = params as { entityId: string };

  const relatedTo = url.split('/')[3] as EntityModules;

  // check if relatedTo is a valid entity module
  if (!Object.values(EntityModules).includes(relatedTo)) {
    return <DataError />;
  }

  const relatedToTranslations = getEntityTranslation[relatedTo];

  const {
    store: { loadDetails, details, path, detailsPath },
    translations: { singular: entitySingular, plural: entityPlural },
    nameKey,
    entityType,
  } = getRelatedEntityDetails(url);

  React.useEffect(() => {
    const fetchDetails = async (): Promise<void> => {
      loadDetails(entityId);
    };

    fetchDetails();
  }, [entityId]);

  return (
    <Record
      Header={
        <MainHeader
          title={`Related ${relatedToTranslations.plural} to ${entitySingular} ${details?.entity[nameKey].value || ''}`}
          breadcrumbs={[
            { label: entityPlural, link: path },
            { label: 'Details', link: detailsPath(entityId) },
            {
              label: `Related ${relatedToTranslations.plural.toLowerCase()}`,
              // @ts-ignore
              link: reverse(routes.dashboard[entityType][`related${relatedToTranslations.plural}`], { entityId }),
            },
          ]}
        />
      }
      Content={(onError) => (
        <Grid
          basicColumns={getBasicColumnsBasedOnEntity(relatedTo)}
          entity={relatedToTranslations.plural}
          onError={onError}
          dataURL={`/api/entities/${entityType}/${entityId}/${relatedTo}/`}
          fieldsURL={`/api/entities/${relatedTo}/fields/`}
          disableSelectAllColumns
          onRowClick={onRowClick}
        />
      )}
      navBarHeight={navBarHeight}
    />
  );
};

export default observer(RelatedEntities);
