import styled from 'styled-components';

export const Wrapper = styled.div`
  border-radius: 8px;
  margin: 8px 0;
  margin-bottom: 16px;
  border-bottom: none;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 20px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
  border-radius: 8px 8px 0 0;
  min-height: 54px;

  background: #f5f5f5;
  h1 {
    margin: 0;
    padding: 0;
    font-size: 18px;
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 20px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-top: none;
  border-radius: 0px 0px 8px 8px;

  background: #f5f5f5;
  h1 {
    margin: 0;
    padding: 0;
    font-size: 18px;
  }
`;

export const DataGridContainer = styled.div`
  background: #fff;
  border-radius: 4px;
`;
