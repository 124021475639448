/* eslint-disable max-len */
import * as React from 'react';

function UserPickerIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M10.4 12.867V12.147C10.4 11.1389 10.4 10.6348 10.2038 10.2498C10.0312 9.91112 9.75587 9.63575 9.41718 9.46318C9.03214 9.26699 8.52809 9.26699 7.52 9.26699H4.88C3.87191 9.26699 3.36786 9.26699 2.98282 9.46318C2.64413 9.63575 2.36876 9.91112 2.19619 10.2498C2 10.6348 2 11.1389 2 12.147V12.867M10.4 3.86699L11.6 5.06699L14 2.66699M8.3 4.76699C8.3 5.92679 7.3598 6.86699 6.2 6.86699C5.0402 6.86699 4.1 5.92679 4.1 4.76699C4.1 3.60719 5.0402 2.66699 6.2 2.66699C7.3598 2.66699 8.3 3.60719 8.3 4.76699Z"
        stroke="black"
        strokeOpacity="0.54"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default UserPickerIcon;
