import { AxiosError, AxiosResponse } from 'axios';
import { observable, runInAction, action, computed, makeObservable } from 'mobx';
import { eventBus, subscribe } from 'mobx-event-bus2';

import { reverse } from 'named-urls';

import { trackEvent } from '@intercom/messenger-js-sdk';

import RootStore from 'stores/Root';
import API from 'utils/api';
import {
  AlarmResponseProfileResponse,
  EscalationAction,
  EscalationActionError,
  EscalationInstruction,
  EscalationProfileCreateARC,
  EscalationProfileCreateResponse,
  EscalationProfileDetailsArcResponse,
  EscalationProfileDetailsResponse,
  EscalationProfileDetailsSelfResponse,
} from 'utils/api/types';
import { EventType } from 'utils/events/constants';
import { postMessage } from 'utils/events/broadcast';
import { MonitoringMethod } from 'core/constants';
import routes from 'core/routes';
import { formErrors, isBadRequest } from 'utils/api/errors';

type DataItem = EscalationInstruction | EscalationAction;

export default class EscalationProfileDetailsStore {
  store: RootStore;

  api: typeof API;

  @observable isLoaded = false;

  @observable error?: AxiosError;

  @observable details?: AlarmResponseProfileResponse;

  @observable arcMonitoredDetails?: EscalationProfileDetailsArcResponse;

  @observable selfMonitoredDetails?: EscalationProfileDetailsSelfResponse;

  constructor(rootStore: RootStore, api: typeof API) {
    makeObservable(this);
    this.store = rootStore;
    this.api = api;

    eventBus.register(this);
  }

  @action.bound
  async loadDetails(escalationProfileId: string): Promise<void> {
    this.isLoaded = false;
    try {
      const { data } = await this.api.loadEscalationProfileDetails(escalationProfileId)();

      runInAction(() => {
        this.error = undefined;
        this.details = data;
        if (data.escalationType === MonitoringMethod.ArcMonitored) {
          const arcDetails = data as EscalationProfileDetailsArcResponse;
          this.arcMonitoredDetails = arcDetails;
          this.selfMonitoredDetails = undefined;
        } else {
          const selfDetails = data as EscalationProfileDetailsSelfResponse;
          this.selfMonitoredDetails = selfDetails;
          this.arcMonitoredDetails = undefined;
        }
      });
    } catch (e) {
      // @ts-ignore
      this.error = e;
    } finally {
      runInAction(() => {
        this.isLoaded = true;
      });
    }
  }

  @action.bound
  updateData(data: Partial<EscalationInstruction>[] | Partial<EscalationAction>[], type: MonitoringMethod): void {
    this.arcMonitoredDetails?.escalationInstructions.forEach(
      (escalationInstruction) => delete escalationInstruction.errors
    );

    if (type === MonitoringMethod.ArcMonitored) {
      const arcDetails = this.details as EscalationProfileDetailsArcResponse;
      this.arcMonitoredDetails = { ...arcDetails, escalationInstructions: data as EscalationInstruction[] };
    } else {
      const selfDetails = this.details as EscalationProfileDetailsSelfResponse;
      this.selfMonitoredDetails = { ...selfDetails, actions: data as EscalationAction[] };
    }

    // after updating data, we need to remove errors for specific fields
    if (type === MonitoringMethod.ArcMonitored) {
      this.arcMonitoredDetails?.escalationInstructions.forEach(
        (escalationInstruction) => delete escalationInstruction.errors
      );
    } else {
      this.selfMonitoredDetails?.actions.forEach((act) => delete act.errors);
    }
  }

  @action.bound
  async createEscalationProfile(
    escalationProfileData: EscalationProfileCreateARC
  ): Promise<AxiosResponse<EscalationProfileCreateResponse>> {
    const result = await this.api.createEscalationProfiles(escalationProfileData)();
    if (this.store.session.user?.appCues) {
      window.Appcues.track('ARP Created');
    }
    if (this.store.session.hasIntercom) {
      trackEvent('ARP Created');
    }

    return result;
  }

  @action
  updateName(escalationName: string | null): void {
    this.details = {
      ...this.details,
      name: escalationName,
    } as AlarmResponseProfileResponse;
  }

  @computed
  get isSaveEnabled(): boolean {
    if (this.details?.escalationType === MonitoringMethod.ArcMonitored) {
      const errors = this.arcMonitoredDetails?.escalationInstructions
        .filter((escalationInstruction) => escalationInstruction.errors)
        .flatMap((escalationInstruction) => escalationInstruction.errors);
      if (errors?.length) {
        return false;
      }
    }

    if (this.details?.escalationType === MonitoringMethod.SelfMonitored) {
      const errors = this.selfMonitoredDetails?.actions
        .filter((escalationAction) => escalationAction.errors)
        .flatMap((escalationAction) => escalationAction.errors);
      if (errors?.length) {
        this.onError(errors);

        return false;
      }
    }

    return true;
  }

  @action.bound
  onError(e: unknown): void {
    this.store.notification.enqueueErrorSnackbar('There was an error updating escalation profile');
    // @ts-ignore
    if (isBadRequest(e)) {
      // @ts-ignore
      const { nonFieldErrors, fieldErrors } = formErrors(e);

      const entities = (this.arcMonitoredDetails?.escalationInstructions || this.selfMonitoredDetails?.actions) as (
        | EscalationInstruction
        | EscalationAction
      )[];
      entities.forEach((item, index) => {
        const foundErrors = (
          ((nonFieldErrors as unknown) as {
            index: string;
            field: 'contactName' | 'phoneNumber';
            message: string;
          }[]) || []
        ).filter((error) => Number(error?.index as string) === index);
        if (foundErrors.length > 0) {
          const errors = [];
          for (const foundError of foundErrors) {
            errors.push({
              key: foundError.field,
              message: foundError.message,
            });
            this.store.notification.enqueueErrorSnackbar(foundError.message);
          }
          item.errors = errors;
        }
      });

      const fieldItemsErrors = (fieldErrors?.escalationInstructions || fieldErrors?.actions || {}) as {
        [key: number]: {
          [key: string]: string[];
        };
      };
      entities.forEach((item, index) => {
        const errors = fieldItemsErrors[index] || {};
        item.errors = [
          ...(Object.entries(errors).map(([fieldName, error]) => ({
            key: fieldName,
            message: error.join('\n'),
          })) as EscalationActionError[]),
          ...(item.errors || []),
        ];
      });

      // depends on the type of escalation profile, we need to update the data
      if (this.details?.escalationType === MonitoringMethod.ArcMonitored) {
        this.arcMonitoredDetails = {
          ...this.arcMonitoredDetails,
          escalationInstructions: entities as EscalationInstruction[],
        };
      } else {
        // @ts-ignore
        this.selfMonitoredDetails = {
          ...this.selfMonitoredDetails,
          actions: entities as EscalationAction[],
        };
      }
    }
  }

  @action.bound
  async create(monitoringMethod: MonitoringMethod): Promise<void> {
    if (this.details?.name === undefined || this.details?.name === '') {
      this.store.notification.enqueueErrorSnackbar(
        'Please enter a name for the alarm response profile. This field is required to create a new profile.'
      );
      return;
    }

    try {
      if (monitoringMethod === MonitoringMethod.ArcMonitored) {
        const arcDetailsCopy = this.arcMonitoredDetails;
        if (arcDetailsCopy?.escalationInstructions) {
          const arcInstructionsCopy = arcDetailsCopy.escalationInstructions.map((instruction) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { uuid, ...rest } = instruction;
            return rest;
          });

          const data = {
            name: this.details.name,
            escalationType: MonitoringMethod.ArcMonitored,
            escalationInstructions: arcInstructionsCopy,
          };

          await this.createEscalationProfile(data as EscalationProfileCreateARC);
        }
      } else if (monitoringMethod === MonitoringMethod.SelfMonitored) {
        const selfDetailsCopy = this.selfMonitoredDetails;
        if (selfDetailsCopy?.actions) {
          const selfActionsCopy = selfDetailsCopy.actions.map((a) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { uuid, ...rest } = a;
            return rest;
          });

          const data = {
            name: this.details.name,
            escalationType: MonitoringMethod.SelfMonitored,
            actions: selfActionsCopy,
            actionFlow: selfDetailsCopy.actionFlow,
          };

          await this.createEscalationProfile(data);
        }
      }

      this.store.notification.enqueueSuccessSnackbar('Alarm response profile successfully created');
      this.store.routing.push(reverse(routes.dashboard.alarms.escalationProfiles.toString()));
    } catch (e) {
      this.onError(e);
    }
  }

  @action.bound
  async save(monitoringMethod: MonitoringMethod): Promise<void> {
    if (this.details?.name === undefined || this.details?.name === '') {
      this.store.notification.enqueueErrorSnackbar(
        'Please enter a name for the alarm response profile. This field is required to create a new profile.'
      );
      return;
    }

    try {
      if (monitoringMethod === MonitoringMethod.ArcMonitored) {
        const arcDetailsCopy = this.arcMonitoredDetails;
        if (arcDetailsCopy?.escalationInstructions) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const updatedInstructions = arcDetailsCopy.escalationInstructions.map(({ uuid, ...rest }) => rest);

          const data: EscalationProfileDetailsResponse = {
            ...this.details,
            escalationInstructions: updatedInstructions,
            actions: undefined,
            actionFlow: undefined,
          };

          await this.api.updateEscalationProfileDetails(data)();
        }
      } else if (monitoringMethod === MonitoringMethod.SelfMonitored) {
        const selfDetailsCopy = this.selfMonitoredDetails;
        if (selfDetailsCopy?.actions) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const updatedActions = selfDetailsCopy.actions.map(({ uuid, ...rest }) => rest);

          if (!this.isSaveEnabled) {
            this.store.notification.enqueueErrorSnackbar('Correct the errors before saving');
            return;
          }

          const data: EscalationProfileDetailsResponse = {
            ...this.details,
            actions: updatedActions,
            actionFlow: selfDetailsCopy.actionFlow,
            escalationInstructions: undefined,
          };

          await this.api.updateEscalationProfileDetails(data)();
        }
      }
      postMessage(EventType.UpdatedEscalationProfile, null);
      this.store.notification.enqueueSuccessSnackbar('Alarm response profile successfully updated');
      this.store.routing.push(reverse(routes.dashboard.alarms.escalationProfiles.toString()));
    } catch (e) {
      this.onError(e);
    }
  }

  @action.bound
  updateDataItem<T extends DataItem>(
    data: T[],
    idOrUuid: number | string,
    newData: Partial<T>,
    updateMethod: MonitoringMethod
  ): void {
    const index = data.findIndex((item) => item.id === idOrUuid || item.uuid === idOrUuid);

    if (index !== -1) {
      const updatedItem = { ...data[index], ...newData };
      const newDataArray = [...data];
      newDataArray[index] = updatedItem;
      this.updateData(newDataArray, updateMethod);
    }
  }

  @action.bound
  removeDataItem<T extends DataItem>(data: T[], idOrUuid: number | string, updateMethod: MonitoringMethod): void {
    const newDataArray = [...data];
    const index = newDataArray.findIndex((item) => item.id === idOrUuid || item.uuid === idOrUuid);

    if (index !== -1) {
      newDataArray.splice(index, 1);
      this.updateData(newDataArray, updateMethod);
    }
  }

  @action.bound
  duplicateDataItem<T extends DataItem>(data: T[], uuid: string, updateMethod: MonitoringMethod): void {
    const newDataArray = [...data];
    const oldIndex = newDataArray.findIndex((item) => item.uuid === uuid);

    if (oldIndex !== -1) {
      const valueToCopy = {
        ...newDataArray[oldIndex],
        uuid: (newDataArray.length as unknown) as string,
        id: newDataArray.length,
      };
      newDataArray.splice(oldIndex + 1, 0, valueToCopy);
      this.updateData(newDataArray, updateMethod);
    }
  }

  @subscribe(EventType.LoggedOut)
  @action.bound
  reset(): void {
    this.isLoaded = false;
    this.error = undefined;
    this.details = undefined;
    this.arcMonitoredDetails = undefined;
    this.selfMonitoredDetails = undefined;
  }
}
