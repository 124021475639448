import React from 'react';

import { Divider, FormControlLabel, IconButton, MenuItem, Switch } from '@mui/material';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { StyledSelect, StyledTextField } from 'components/Input/styles';

import { ProtectorType } from 'utils/api/types';

import { useStore } from 'utils/hooks/store';

import { FieldContainer, FieldProperties, Row } from './styles';
import { getProtectorType, possibleProtectorTypesForQuestions } from './utils';
import { EditFieldProps } from './types';
import EditComponentForProtectorType from '../EditComponentForProtectorType/EditComponentForProtectorType';

const EditField: React.FC<EditFieldProps> = ({
  description,
  questionType,
  lookup,
  sectionId,
  fieldId,
  onChangeFieldProperties,
}): React.ReactElement => {
  const {
    session: { user },
    formBuilder,
  } = useStore();

  const onDeleteField = (): void => {
    formBuilder.deleteField(sectionId, fieldId);
  };

  const onDuplicateField = (): void => {
    formBuilder.duplicateField(sectionId, fieldId);
  };

  const onMakeRequired = (): void => {
    formBuilder.makeFieldRequired(sectionId, fieldId);
  };

  const isRequired = formBuilder.isFieldRequired(sectionId as string, fieldId as string);

  return (
    <FieldContainer>
      <Row>
        <StyledTextField
          fullWidth
          size="small"
          style={{ backgroundColor: '#fff' }}
          defaultValue={description || ''}
          onChange={(e) => onChangeFieldProperties(e.target.value, 'description')}
          placeholder="Description (Optional)"
        />
        <StyledSelect
          fullWidth
          placeholder="Choose an Option"
          value={questionType}
          onChange={(e) => onChangeFieldProperties(e.target.value as string, 'questionType')}
          $withoutPadding
          style={{
            height: 'auto',
            backgroundColor: '#fff',
            padding: '8px 14px',
          }}
          sx={{
            '.MuiSelect-select': {
              gap: '8px',
            },
          }}
        >
          {possibleProtectorTypesForQuestions.map((type) => {
            const { displayName, icon } = getProtectorType(type);
            return (
              <MenuItem
                key={type}
                value={type}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                }}
              >
                {icon}
                {displayName}
              </MenuItem>
            );
          })}
        </StyledSelect>
      </Row>
      <Row style={{ background: '#fff' }}>
        <EditComponentForProtectorType
          questionType={questionType as ProtectorType}
          activeEntityModules={user?.activeEntityModules}
          lookupData={lookup}
          onSettingLookup={(val) => onChangeFieldProperties(val, 'lookupType')}
        />
      </Row>

      {questionType === ProtectorType.Lookup && (
        <Row style={{ background: '#fff' }}>
          <InfoOutlinedIcon htmlColor="rgba(0, 0, 0, 0.54)" fontSize="small" />
          <p style={{ margin: 0, padding: 0, color: 'rgba(0, 0, 0, 0.60)' }}>
            Lookup fields allow users to relate this event with records from another module.
          </p>
        </Row>
      )}
      <FieldProperties>
        <FormControlLabel
          control={<Switch checked={isRequired} onClick={onMakeRequired} name="required" size="small" />}
          label={<p style={{ fontSize: '14px', margin: 0, padding: 0 }}>Required</p>}
        />
        <Divider orientation="vertical" flexItem />

        <IconButton size="small" onClick={onDuplicateField}>
          <ContentCopyIcon />
        </IconButton>
        <IconButton size="small" onClick={onDeleteField}>
          <DeleteOutlineIcon />
        </IconButton>
      </FieldProperties>
    </FieldContainer>
  );
};

export default EditField;
