import { styled } from 'styled-components';

export const DisplayFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 8px;
`;

export const Title = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-size: 12px;
  font-weight: 500;
  margin: 0;
  padding: 0;
`;
