export const AutocompleteStyle = {
  style: {
    height: 'fit-content',
    width: '100%',
  },
  sx: {
    '& + .MuiAutocomplete-popper .MuiAutocomplete-option': {
      fontSize: '16px',
    },
    '& + .MuiChip-root': {
      fontSize: '16px',
    },
  },
};
