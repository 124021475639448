import { EntityEnumsOptions, EntityType, ProtectorType } from 'utils/api/types';

import { InitialValues } from './types';

export const transformInitialValues = (en: EntityType): InitialValues => {
  const initialValues: InitialValues = {};
  Object.keys(en).forEach((key) => {
    initialValues[key] = en[key].value;
  });
  return initialValues;
};

export const transformValues = (
  values: InitialValues,
  initialValues: InitialValues,
  entity: EntityType
): InitialValues => {
  Object.keys(values).forEach((key) => {
    if (values[key] === initialValues[key]) {
      delete values[key];
      return;
    }
    if (entity[key].schema.protectorType === ProtectorType.User) {
      if (values[key] === null) {
        values[key] = null;
      } else {
        const [, value] = String(values[key]).split(':');
        values[key] = value;
      }
    }
    if (values[key] === null || values[key] === undefined || (values[key] as EntityEnumsOptions[]).length === 0) {
      values[key] = null;
      return;
    }

    if (entity[key].schema.protectorType === ProtectorType.MultiChoice) {
      values[key] = (values[key] as EntityEnumsOptions[]).map((v) => v.key);
    }

    if (entity[key].schema.protectorType === ProtectorType.SingleChoice) {
      values[key] = [(values[key] as EntityEnumsOptions[])[0].key];
    }
  });
  return values;
};
