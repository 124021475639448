import React from 'react';
import { AutocompleteRenderGetTagProps, AutocompleteRenderInputParams, FormControl, TextField } from '@mui/material';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { ProtectorType } from 'utils/api/types';

import { StyledAutocomplete } from 'components/Input/styles';

import Chip from 'components/Chip';

import { EntityDropdownFieldProps } from '../types';
import { AutocompleteStyle } from './styles';

export const EntityDropdownField = ({
  type,
  value,
  items,
  description,
  onChange,
  requiredNotFilled,
}: EntityDropdownFieldProps): React.ReactElement => {
  const multiple = type === ProtectorType.MultiChoice;

  const getValue = (
    rawValue: { key: string; value: string }[] | null | string
  ): { key: string; value: string } | { key: string; value: string }[] | null | string => {
    if (rawValue === null || rawValue === undefined || rawValue === '' || value === null) {
      return type === ProtectorType.MultiChoice ? [] : '';
    }
    if (type === ProtectorType.MultiChoice) {
      return (value as unknown) as { key: string; value: string }[];
    }
    // if value is not an array, return value
    return value[0] as { key: string; value: string };
  };

  const renderInput = (params: AutocompleteRenderInputParams): React.ReactNode => (
    <TextField
      {...params}
      error={requiredNotFilled}
      placeholder={type === ProtectorType.MultiChoice ? 'Choose option(s)' : 'Choose an option'}
    />
  );

  const renderTags = (
    tagValue: {
      key: string;
      value: string;
    }[],
    getTagProps: AutocompleteRenderGetTagProps
  ): React.ReactNode =>
    tagValue.map((option, index) => (
      <Chip {...getTagProps({ index })} label={`${option.value}`} fontSize={14} variant="filled" />
    ));

  return (
    <FormControl fullWidth>
      <StyledAutocomplete
        freeSolo
        fullWidth
        popupIcon={<ArrowDropDownIcon />}
        forcePopupIcon
        size="medium"
        disablePortal
        role="textbox"
        multiple={multiple}
        options={items.enum}
        {...AutocompleteStyle}
        value={getValue(value)}
        data-testid={description}
        renderTags={renderTags}
        renderInput={renderInput}
        disableCloseOnSelect={multiple}
        id={`${multiple ? 'multi' : 'single'}-drop-down-field`}
        getOptionLabel={(option) => `${typeof option === 'string' ? option : option.value}`}
        isOptionEqualToValue={(option: { key: string }, val: { key: string }) =>
          val.key !== '' && val.key === option.key
        }
        onInputChange={(_, __, reason) => {
          if (reason === 'clear') {
            onChange(null);
          }
        }}
        onChange={(_, newValue) => {
          if (newValue) {
            if (type === ProtectorType.SingleChoice) {
              onChange([newValue] as string[]);
            } else {
              onChange(newValue as string[]);
            }
          } else {
            onChange(null);
          }
        }}
      />
    </FormControl>
  );
};

export default EntityDropdownField;
