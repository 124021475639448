import React from 'react';

import { Button } from 'vatix-ui/lib/components/Button';

import { SortableItemType } from '../types';
import { InactiveItemContainer } from './styles';

const InactiveItems: React.FunctionComponent<{
  inactiveOptions: SortableItemType[];
  handleActiveChange: (key: string) => void;
}> = ({ inactiveOptions, handleActiveChange }) =>
  inactiveOptions.map((item) => (
    <InactiveItemContainer>
      <p>{item.value}</p>
      <p>API Name: {item.displayKey}</p>
      <Button variant="text" color="primary" size="small" onClick={() => handleActiveChange(item.key)}>
        Activate
      </Button>
    </InactiveItemContainer>
  ));

export default InactiveItems;
