/* eslint-disable max-len */
import * as React from 'react';

function LookupIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M8.66406 1.33301H3.9974C3.64377 1.33301 3.30464 1.47348 3.05459 1.72353C2.80454 1.97358 2.66406 2.31272 2.66406 2.66634V13.333C2.66406 13.6866 2.80454 14.0258 3.05459 14.2758C3.30464 14.5259 3.64377 14.6663 3.9974 14.6663H11.9974C12.351 14.6663 12.6902 14.5259 12.9402 14.2758C13.1903 14.0258 13.3307 13.6866 13.3307 13.333V5.99967L8.66406 1.33301Z"
        stroke="#757575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.66406 1.33301V5.99967H13.3307"
        stroke="#757575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default LookupIcon;
