import { GridValueGetterParams } from '@mui/x-data-grid';

import { defaultRenderers } from 'components/Grid';
import { ExtendedUserOperators } from 'components/Grid/operators/User/User';
import { ColumnDefinition } from 'components/Grid/types';
import { EntityModules } from 'core/constants';
import { getEntityTranslation } from 'stores/EntityDetails/utils';

// Events: Name, Type, Status
// Sites: Name, Manager
// Assets: Name
// Suppliers: Name

export const basicColumnsForEntity = (module: EntityModules): ColumnDefinition[] => {
  const { singular } = getEntityTranslation[module];
  switch (module) {
    case EntityModules.Sites:
      return [
        {
          field: `sites__siteName`,
          headerName: 'Name',
          nativeFilter: true,
        },
        {
          field: 'sites__siteManager',
          headerName: 'Manager',
          renderCell: defaultRenderers.user,
          valueGetter: ({ value }: GridValueGetterParams) => ({ value }),
          filterOperators: ExtendedUserOperators(),
          sortable: false,
        },
      ] as ColumnDefinition[];
    case EntityModules.Assets:
      return [
        {
          field: `assets__assetName`,
          headerName: 'Name',
          nativeFilter: true,
        },
      ] as ColumnDefinition[];
    // TODO: Update after implementing Events data grid and record view
    case EntityModules.Events:
      return [
        {
          field: `events__eventName`,
          headerName: 'Name',
          nativeFilter: true,
        },
        {
          field: 'type',
          headerName: 'Type',
          nativeFilter: true,
        },
        {
          field: 'status',
          headerName: 'Status',
          nativeFilter: true,
        },
      ] as ColumnDefinition[];
    case EntityModules.Suppliers:
      return [
        {
          field: `suppliers__supplierName`,
          headerName: 'Name',
          nativeFilter: true,
        },
      ] as ColumnDefinition[];
    default:
      return [
        {
          field: `${singular.toLowerCase()}Name`,
          headerName: 'Name',
          nativeFilter: true,
        },
      ] as ColumnDefinition[];
  }
};
