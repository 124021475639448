import { createTheme, Theme as MuiTheme } from '@mui/material/styles';

export const Theme: MuiTheme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        h1: {
          color: '#868585',
          fontSize: '4.8rem',
          fontWeight: 300,
          lineHeight: '5.7rem',
        },
        h2: {
          color: '#FFFFFF',
          fontSize: '2.4rem',
          lineHeight: '2.8rem',
          // @ts-ignore
          textDecoration: 'none',
          fontWeight: 'normal',
        },
        h4: {
          color: '#595959',
          fontSize: '1.4rem',
          fontWeight: 'bold',
          lineHeight: '1.6rem',
          // @ts-ignore
          margin: '16px 0',
        },
        h6: {
          color: '#595959',
          fontSize: '2.4rem',
          lineHeight: '2.8rem',
          fontWeight: 'normal',
        },
        subtitle1: {
          color: '#FFFFFF',
          fontSize: '1.3rem',
          lineHeight: '1.6rem',
          // @ts-ignore
          textAlign: 'center',
          fontWeight: 'normal',
          margin: '20px 0',
        },
        body1: {
          color: '#555555',
          fontSize: '1.4rem',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        message: {
          fontSize: '1.3rem',
          fontWeight: 'bold',
          lineHeight: '1.5rem',
          color: '#FFFFFF',
        },
      },
    },
    // @ts-ignore
    MuiPickersDay: {
      styleOverrides: {
        dayWithMargin: {
          fontSize: '14px',
          color: '#555555',
          lineHeight: 'normal',
          letterSpacing: 'normal',
        },
      },
    },
    MuiDayCalendar: {
      styleOverrides: {
        weekDayLabel: {
          fontSize: '14px',
          color: '#555555 ',
          lineHeight: 'normal',
          letterSpacing: 'normal',
        },
      },
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        labelContainer: {
          fontSize: '14px',
          color: '#555555 ',
        },
      },
    },
    MuiPickersYear: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          color: '#555555',
        },
        yearButton: {
          fontSize: '14px',
          color: '#555555 ',
        },
      },
    },
    MuiTimeClock: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          color: '#555555',
        },
      },
    },
    MuiClock: {
      styleOverrides: {
        meridiemText: {
          fontSize: '12px',
        },
      },
    },
    MuiMultiSectionDigitalClock: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          color: '#555555 ',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        noOptions: {
          fontSize: '14px',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 11,
          backgroundColor: 'rgba(97, 97, 97, 0.90)',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          margin: 0,
          paddingTop: 11,
          paddingBottom: 11,
          fontSize: '16px',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: 24,
        },
        fontSizeLarge: {
          fontSize: 35,
        },
        fontSizeSmall: {
          fontSize: 20,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        head: {
          height: 56,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '4px',
          borderBottom: 'none',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: '24px 20px',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          fontSize: 14,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow:
            '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          fontSize: '16px !important',
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          fontSize: '12px',
        },
      },
    },
  },
});
