/* eslint-disable max-len */
import * as React from 'react';

export function SectionsIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <mask id="path-1-inside-1_21654_285561" fill="white">
        <rect x="3" y="13" width="18" height="8" rx="1" />
      </mask>
      <rect
        x="3"
        y="13"
        width="18"
        height="8"
        rx="1"
        stroke="#0772E5"
        strokeWidth="2.6"
        mask="url(#path-1-inside-1_21654_285561)"
      />
      <mask id="path-2-inside-2_21654_285561" fill="white">
        <rect x="3" y="3" width="18" height="8" rx="1" />
      </mask>
      <rect
        x="3"
        y="3"
        width="18"
        height="8"
        rx="1"
        stroke="#0772E5"
        strokeWidth="2.6"
        mask="url(#path-2-inside-2_21654_285561)"
      />
    </svg>
  );
}
