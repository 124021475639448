import styled from 'styled-components';

export const FieldHeader = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  font-weight: 400;
  margin: 14px 0 4px 0;
`;

export const StyledModalActions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 10px;
`;
