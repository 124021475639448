import React, { useState, useMemo } from 'react';

import {
  DndContext,
  DragOverlay,
  UniqueIdentifier,
  Over,
  useSensors,
  useSensor,
  MouseSensor,
  TouchSensor,
  MeasuringStrategy,
  Active,
} from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';

import { observer } from 'mobx-react';

import { Button } from 'vatix-ui/lib/components/Button';

import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

import { useStore } from 'utils/hooks/store';

import EmptyLayout from 'containers/LayoutEditor/components/helpers/EmptyLayout';

import { StyledTextField } from 'components/Input/styles';

import { SectionsIcon } from 'components/Icons/SectionsIcon';

import { FIELD_MANAGER_HELP_URL } from 'core/constants';

import Section from '../Section';
import Item from '../Items/components/Item';
import { FormNameTitle, MainContainer } from './styles';

const FormBuilderDragAndDrop = (): React.ReactElement => {
  const { formBuilder } = useStore();

  const [formName, setFormName] = useState<string>(formBuilder.formName);

  const updateFormName = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setFormName(event.target.value);
    formBuilder.updateFormName(event.target.value);
  };

  const renderingCondition = useMemo(
    () =>
      formBuilder.items === null ||
      Object.keys(formBuilder.items).length === 0 ||
      !formBuilder.items ||
      Number(formBuilder.items.length) === 0 ||
      formBuilder.containers.length === 0,
    [formBuilder.items, formBuilder.containers]
  );

  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const [clonedItems, setClonedItems] = useState<Record<UniqueIdentifier, UniqueIdentifier[]> | null>(null);

  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const handleAddSection = (): void => {
    formBuilder.addSection();
  };

  const measuring = {
    droppable: {
      strategy: MeasuringStrategy.Always,
    },
  };

  const onDragCancel = (): void => {
    if (clonedItems) {
      formBuilder.setItems(clonedItems);
    }

    setActiveId(null);
    setClonedItems(null);
  };

  const onDragStart = ({ active }: { active: { id: UniqueIdentifier } }): void => {
    setActiveId(active.id);
    setClonedItems(formBuilder.items);
  };

  const onDragOver = ({ active, over }: { active: Active; over: Over | null }): void => {
    const overId = over?.id;
    // If there is no item being dragged over or the active item is already in the formBuilder items, return early
    if (overId == null || active.id in formBuilder.items) {
      return;
    }

    const overContainer = formBuilder.findItem(overId as string);
    const activeContainer = formBuilder.findItem(active.id as string);

    // If the active item is not in a container and is being dragged over a container, add it to the new container
    if (overContainer && !activeContainer) {
      formBuilder.modifyItemsInContainer(
        overContainer as string,
        [...formBuilder.items[overContainer], active.id] as string[]
      );
      return;
    }

    // If either the active or over container is not found, return early
    if (!overContainer || !activeContainer) {
      return;
    }

    // If the active item is being dragged between different containers, move it between the containers
    if (activeContainer !== overContainer) {
      formBuilder.moveItemsBetweenContainers(activeContainer, overContainer, overId as string, active, over as Over);
    }
  };

  const onDragEnd = ({ active, over }: { active: Active; over: Over | null }): void => {
    // Find the container that the active item belongs to
    const activeContainer = formBuilder.findItem(active.id as string);

    // If the active item is not in any container, reset the active ID and return early
    if (!activeContainer) {
      setActiveId(null);
      return;
    }

    // Get the ID of the item being dragged over
    const overId = over?.id;

    // If there is no item being dragged over, reset the active ID and return early
    if (overId == null) {
      setActiveId(null);
      return;
    }

    // Find the container that the item being dragged over belongs to
    const overContainer = formBuilder.findItem(overId as string);

    // If the item being dragged over is in a container
    if (overContainer) {
      // Get the index of the active item in its container
      const activeIndex = formBuilder.items[activeContainer].indexOf(active.id);
      // Get the index of the item being dragged over in its container
      const overIndex = formBuilder.items[overContainer].indexOf(overId);

      // If the active item and the item being dragged over are not at the same index
      if (activeIndex !== overIndex) {
        // Move the active item to the new index in the container
        formBuilder.modifyItemsInContainer(
          overContainer as string,
          arrayMove(formBuilder.items[overContainer], activeIndex, overIndex) as string[]
        );
      }
    }

    // Reset the active ID
    setActiveId(null);
  };

  const redirectToHelpCenter = (): void => {
    // TODO: add link to help center
    window.open(FIELD_MANAGER_HELP_URL, '_blank');
  };

  return (
    <>
      <Button
        onClick={redirectToHelpCenter}
        variant="text"
        size="large"
        endIcon={<HelpOutlineOutlinedIcon />}
        style={{
          marginRight: '4px',
          background: 'rgba(7, 114, 229, 0.08)',
          position: 'fixed',
          top: '50%',
          right: '0px',
          transform: 'translateY(-50%)  rotate(-90deg)',
        }}
      >
        Help Center
      </Button>

      <MainContainer>
        <FormNameTitle>Form</FormNameTitle>
        <StyledTextField
          size="small"
          fullWidth
          value={formName}
          onChange={updateFormName}
          placeholder="Enter form name"
          style={{ background: '#fff', borderRadius: '4px', marginBottom: '16px' }}
        />
        <DndContext
          sensors={sensors}
          measuring={measuring}
          onDragStart={onDragStart}
          onDragOver={onDragOver}
          onDragEnd={onDragEnd}
          onDragCancel={onDragCancel}
        >
          {renderingCondition ? (
            <EmptyLayout />
          ) : (
            formBuilder.containers.map((containerId) => (
              <Section items={formBuilder.items[containerId] as string[]} id={containerId} />
            ))
          )}

          <Button
            size="large"
            variant="outlined"
            disabled={false}
            onClick={handleAddSection}
            startIcon={<SectionsIcon />}
          >
            Add Section
          </Button>
          {useMemo(
            () => (
              <DragOverlay adjustScale={false}>
                {activeId &&
                  (formBuilder.containers.includes(activeId.toString()) ? (
                    formBuilder.items[activeId].map((item) => <Item key={item} id={activeId} />)
                  ) : (
                    <Item id={activeId} />
                  ))}
              </DragOverlay>
            ),
            [activeId, formBuilder.items, formBuilder.containers]
          )}
        </DndContext>
      </MainContainer>
    </>
  );
};

export default observer(FormBuilderDragAndDrop);
