import React from 'react';

import { EntityEnumsOptions, EntityValueType, JSONSchemaType, ProtectorType } from 'utils/api/types';

import { EmptyValue } from 'containers/IncidentDetails/components/CustomFieldDisplayValue/styles';

import { Value } from 'containers/IncidentDetails/components/EditableTextInput/styles';
import EntityRowWrapper from 'components/Entities/EntityDetailsTab/RowWrapper';

type EntitySectionProps = {
  withDivider: boolean;
  item: {
    schema: JSONSchemaType;
    value: EntityValueType;
  };
};

const EntityDropdownDisplayValue: React.FunctionComponent<EntitySectionProps> = ({
  withDivider,
  item,
}): React.ReactElement => {
  const value = item.value as EntityEnumsOptions[];
  const renderValue = (): JSX.Element => {
    if (!value || value.length === 0) {
      return <EmptyValue id="empty-value">-</EmptyValue>;
    }
    if (item.schema.protectorType === ProtectorType.MultiChoice) {
      return <Value>{value.map((v) => v.value).join(', ')}</Value>;
    }
    return <Value>{value[0].value}</Value>;
  };

  return (
    <EntityRowWrapper rowName={item.schema.name || 'N/A'} withDivider={withDivider}>
      {renderValue()}
    </EntityRowWrapper>
  );
};

export default EntityDropdownDisplayValue;
